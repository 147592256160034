@use 'sass:color';
@use '~$app-web/ui-kit/styles/theme';

.loadingBarWrapper {
    height: 100%;
    overflow: hidden;
}

.parentOverlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
}

.hideInline {
    visibility: hidden;
}

.backdropLight {
    background: rgba(230, 230, 230, 0.8);
}

.backdropDark {
    background: rgba(0, 0, 0, 0.8);
}

.errorWrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.error {
    background: theme.color(snow-50);
    border-radius: theme.get-border-radius(md);
    height: 300px;
    margin: auto;
    width: 500px;
}
